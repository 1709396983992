<template>
  <div id="root">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {}
};
</script>

<style>
/* root-space */
#root {
  margin: 0 auto;
  width: 93%;
}
.title-box {
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 5px;
  position: relative;
  width: 93%;
  border-bottom: 1px solid #efefef;
  padding-bottom: 50px;
}
.title-box a {
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #007bff;
  font-weight: 400;
}
.title-box h2 {
  font-size: 18px;
  margin: 0;
  line-height: 6.9444vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
}
</style>
